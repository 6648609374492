import Preloader from "./Preloader";
import theme from "./Theme";
import ShowLoader from "./ShowLoader";
import MainHeader from "./Header";
import MainFooter from "./Footer";

export {
  Preloader,
  theme,
  ShowLoader,
  MainHeader,
  MainFooter,
};
