import { SAVE_LOGIN } from "../actions/loginActions";
const initState = {
  id_user: 0,
  api_token: "",
};

const loginReducer = (state = initState, action) => {
  if (action.type === SAVE_LOGIN) {
    return {
      ...state,
      id_user: action.payload.id_user,
      api_token: action.payload.api_token
    };
  }
  return state;
};

export default loginReducer;
