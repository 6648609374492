import React, {Component} from "react";
import ReactModal from 'react-modal';
import Loader from 'react-loader-spinner';

const ShowLoader = () => {
  return (
    <ReactModal 
     isOpen={true}
     contentLabel="Loading"
     style={{
       overlay: {
         backgroundColor: 'rgba(0,0,0,0.8)',
         zIndex: 100,
       },
       content: {
      position: 'absolute',
      top: '40vh',
      left: '47vw',
      right: '47vw',
      bottom: '40vh',
      width:'6vh',
      height:'6vh',
      borderColor: '#63ceb0',
      borderRadius:'100vw',
      background: 'rgba(0,0,0,0)',
      overflow: 'hidden',
      WebkitOverflowScrolling: 'touch',
      outline: 'none',
    }
   }}
  >
  <Loader
     type="Bars"
     color="#63ceb0"
     height={'6vh'}
     width={'6vh'}
  />
</ReactModal>
  );
};

export default ShowLoader;
