export const ADD_TO_CART = "ADD_TO_CART";
export const INCREASE_QUANTITY = "INCREASE_QUANTITY";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const DELETE_ALL_FROM_CART = "DELETE_ALL_FROM_CART";

export const addToCartDispatch = (
  item,
  quantityCount,
) => {
  return dispatch => {
    dispatch({
      type: ADD_TO_CART,
      payload: {
        ...item,
        quantity: quantityCount,
      }
    });
  };
};

export const increaseQuantityDispatch = item => {
  return dispatch => {
    dispatch({ type: INCREASE_QUANTITY, payload: item });
  };
};

export const decreaseQuantityDispatch = item => {
  return dispatch => {
    dispatch({ type: DECREASE_QUANTITY, payload: item });
  };
};

export const deleteFromCartDispatch = item => {
  return dispatch => {
    dispatch({ type: DELETE_FROM_CART, payload: item });
  };
};

export const deleteAllFromCartDispatch = () => {
  return dispatch => {
    dispatch({ type: DELETE_ALL_FROM_CART });
  };
};