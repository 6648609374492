import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AppRoute from "./helpers/AppRoute";

import { DefaultLayout } from "./layouts";
//import "./assets/scss/style.scss";
import "./assets/App.css";
import { Preloader } from "./components";
import ScrollToTop from "./helpers/scroll-top";

const Home = lazy(() => import("./pages/Home"));
const Login = lazy(() => import("./pages/Login"));
const Signup = lazy(() => import("./pages/Signup"));
// const EditProfile = lazy(() => import("./pages/EditProfile"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const Products = lazy(() => import("./pages/Products"));
const Category = lazy(() => import("./pages/Category"));
const Faq = lazy(() => import("./pages/Faq"));
const Product = lazy(() => import("./pages/Product"));
const GarageSale = lazy(() => import("./pages/GarageSale"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const Inspiration = lazy(() => import("./pages/Inspiration"));
const Articles = lazy(() => import("./pages/Articles"));
const ArticleID = lazy(() => import("./pages/ArticleID"));
const Checkout = lazy(() => import("./pages/Checkout"));
const ThankYou = lazy(() => import("./pages/ThankYou"));
const MyAccount = lazy(() => import("./pages/MyAccount"));
const MyAddress = lazy(() => import("./pages/MyAddress"));
const MyOrder = lazy(() => import("./pages/MyOrder"));
const MyProfile = lazy(() => import("./pages/MyProfile"));
const MyWarranties = lazy(() => import("./pages/MyWarranties"));
const Invoice = lazy(() => import("./pages/Invoice"));
const ConfirmPayment = lazy(() => import("./pages/ConfirmPayment"));
const TermsConditions = lazy(() => import("./pages/TermsConditions"));
const UpspaceDream = lazy(() => import("./pages/UpspaceDream"));

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Suspense fallback={<Preloader />}>
          <Switch>
            <Route
              path={process.env.PUBLIC_URL + "/"}
              exact
              component={Home}
            />
            <Route
              path={process.env.PUBLIC_URL + "/login"}
              /*component={Home}*/
              render={props => <Login {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/signup"}
              /*component={Home}*/
              render={props => <Signup {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/forgot-password"}
              /*component={Home}*/
              render={props => <ForgotPassword {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/reset-password"}
              /*component={Home}*/
              render={props => <ResetPassword {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/products"}
              /*component={Home}*/
              render={props => <Products {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/category"}
              /*component={Home}*/
              render={props => <Category {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/faq"}
              /*component={Home}*/
              render={props => <Faq {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/product"}
              /*component={Home}*/
              render={props => <Product {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/garage-sale"}
              /*component={Home}*/
              render={props => <GarageSale {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/about-us"}
              /*component={Home}*/
              render={props => <AboutUs {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/inspiration"}
              /*component={Home}*/
              render={props => <Inspiration {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/articles"}
              /*component={Home}*/
              render={props => <Articles {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/article-id"}
              /*component={Home}*/
              render={props => <ArticleID {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/checkout"}
              /*component={Home}*/
              render={props => <Checkout {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/thank-you"}
              /*component={Home}*/
              render={props => <ThankYou {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/my-account"}
              /*component={Home}*/
              render={props => <MyAccount {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/my-address"}
              /*component={Home}*/
              render={props => <MyAddress {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/my-order"}
              /*component={Home}*/
              render={props => <MyOrder {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/my-profile"}
              /*component={Home}*/
              render={props => <MyProfile {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/my-warranties"}
              /*component={Home}*/
              render={props => <MyWarranties {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/invoice"}
              /*component={Home}*/
              render={props => <Invoice {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/confirm-payment"}
              /*component={Home}*/
              render={props => <ConfirmPayment {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/terms-conditions"}
              /*component={Home}*/
              render={props => <TermsConditions {...props} />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/upspace-dream"}
              /*component={Home}*/
              render={props => <UpspaceDream {...props} />}
            />
          </Switch>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
}

export default App;
