// import productReducer from "./productReducer";
// import wishlistReducer from "./wishlistReducer";
import cartReducer from "./cartReducer";
import loginReducer from "./loginReducer";
import userReducer from "./userReducer";
import langReducer from "./langReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  // productData: productReducer,
  // wishlistData: wishlistReducer,
  loginData: loginReducer,
  userData: userReducer,
  cartData: cartReducer,
  langData: langReducer,
});

export default rootReducer;
