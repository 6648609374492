import { SWITCH_LANG } from "../actions/langActions";
const initState = {
    lang: "id"
};

const langReducer = (state = initState, action) => {
  const lang = state;
  
  if (action.type === SWITCH_LANG) {
    let lang = action.payload;
    
    return lang;
  }
  
  return state;
};

export default langReducer;
