import axios from 'axios'
import { Config } from './Config'
import { is, curryN, gte } from 'ramda'

const gfa = 500

const baseurl = Config.API_URL;
const token = Config.API_TOKEN;

const isWithin = curryN(3, (min, max, value) => {
  const isNumber = is(Number)
  return isNumber(min) && isNumber(max) && isNumber(value) && gte(value, min) && gte(max, value)
})
const in200s = isWithin(200, 299)

export const apiconn = {
	gfa: gfa,
	in200s: in200s,
	xenditPost: axios.create({
		  baseURL: 'https://api.xendit.co',
		  headers: {
			//'Accept': 'application/json',
			//'Content-Type': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': 'Basic eG5kX2RldmVsb3BtZW50X1dxNE5RaGdHcVlHRUJPaFBmMk1idlM0ZkNpOUZIM2RSMm50bHY5cVpMUEZBVTc5cVdXS2lKeVZ2OU9TOg==',
			// 'Access-Control-Allow-Origin': '*',
			// 'Access-Control-Allow-Methods': 'GET, POST, PUT',
			// 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
		  },
		  // auth: {
			//   username: 'xnd_development_Wq4NQhgGqYGEBOhPf2MbvS4fCi9FH3dR2ntlv9qZLPFAU79qWWKiJyVv9OS',
			//   password: ''
		  // },
		  method: 'POST',
		  timeout: 15000,
		}),
	postApi: axios.create({
		  baseURL: baseurl,
		  headers: {
		    'Accept': 'application/json',
		    'Content-Type': 'application/json',
		    'Authorization': 'Basic ' + token,
		  },
		  method: 'post',
		  timeout: 15000,
		}),
	postFileApi: axios.create({
		  baseURL: baseurl,
		  headers: {
		    'Accept': 'application/json',
		    // 'Content-Type': 'multipart/form-data',
		    'Authorization': 'Basic ' + token,
		  },
		  method: 'post',
		  timeout: 15000,
		}),
	getApi: axios.create({
		  baseURL: baseurl,
		  headers: {
		    'Accept': 'application/json',
		    'Content-Type': 'application/json',
		    'Authorization': 'Basic ' + token,
		  },
		  method: 'get',
		  timeout: 15000,
		}),
	putApi: axios.create({
		  baseURL: baseurl,
		  headers: {
		    'Accept': 'application/json',
		    'Content-Type': 'application/json',
		    'Authorization': 'Basic ' + token,
		  },
		  method: 'put',
		  timeout: 15000,
		}),
	delApi: axios.create({
		  baseURL: baseurl,
		  headers: {
		    'Accept': 'application/json',
		    'Content-Type': 'application/json',
		    'Authorization': 'Basic ' + token,
		  },
		  method: 'delete',
		  timeout: 15000,
		}),
}