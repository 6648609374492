import { v4 as uuid } from "uuid";
import {
  ADD_TO_CART,
  INCREASE_QUANTITY,
  DECREASE_QUANTITY,
  DELETE_FROM_CART,
  DELETE_ALL_FROM_CART
} from "../actions/cartActions";

const initState = [];

const cartReducer = (state = initState, action) => {
  const cartItems = state;
  
  if (action.type === ADD_TO_CART) {
    let product = action.payload;
    
    const cartItem = cartItems.filter(item => item.id_variant === product.id_variant)[0];
    if (cartItem === undefined) {
      return [
        ...cartItems,
        {
          ...product,
          quantity: product.quantity ? product.quantity : 1,
          cartItemId: uuid()
        }
      ];
    } else {
      return cartItems.map(item =>
        item.cartItemId === cartItem.cartItemId
          ? {
              ...item,
              quantity: product.quantity
                ? item.quantity + product.quantity
                : item.quantity + 1
            }
          : item
      );
    }
    
  }
  
  if (action.type === INCREASE_QUANTITY) {
    let product = action.payload;
    
    return cartItems.map(item =>
      item.id_variant === product.id_variant
        ? { ...item, quantity: item.quantity + 1 }
        : item
    );
  }
  
  if (action.type === DECREASE_QUANTITY) {
    let product = action.payload;
    
    const cartItem = cartItems.filter(item => item.id_variant === product.id_variant)[0];
    
    if (cartItem.quantity == 1) {
      const remainingItems = (cartItems, product) =>
        cartItems.filter(
          cartItem => cartItem.id_variant != product.id_variant
        );
      return remainingItems(cartItems, product);
    } else {
      return cartItems.map(item =>
        item.id_variant === product.id_variant
          ? { ...item, quantity: item.quantity - 1 }
          : item
      );
    }
  }
  
  if (action.type === DELETE_FROM_CART) {
    let product = action.payload;
    
    const remainingItems = (cartItems, product) =>
      cartItems.filter(cartItem => cartItem.id_variant != product.id_variant);
    return remainingItems(cartItems, product);
  }
  
  if (action.type === DELETE_ALL_FROM_CART) {
    return [];
  }
  
  return state;
};

export default cartReducer;
