import { FETCH_USER_SUCCESS } from "../actions/userActions";
const initState = {
  data: {
      id_user: 0,
      username: "",
      fistname: "",
      lastname: "",
      email: "",
      customer: null,
    }
};

const userReducer = (state = initState, action) => {
  if (action.type === FETCH_USER_SUCCESS) {
    return {
      ...state,
      data: action.payload
    };
  }
  return state;
};

export default userReducer;
